import { StaticImage } from "gatsby-plugin-image"
import { ExternalButton } from "../ExternalButton"
import React from "react"
import { useIntl } from "gatsby-plugin-react-intl"
import {
  Container,
  Top,
  Thumbnail,
  Info,
  Images,
  ImageWrapper,
  Description,
} from "./style"

export const FoxRunner = () => {
  const intl = useIntl()
  return (
    <Container>
      <Top>
        <Thumbnail>
          <StaticImage
            src="../../images/foxrunner-thumbnail.jpg"
            alt="logo"
            placeholder="blurred"
            quality={100}
          />
        </Thumbnail>
        <Info>
          <h1>{intl.formatMessage({ id: "FoxrunnerTitle" })}</h1>
          <p>{intl.formatMessage({ id: "FoxrunnerSmallDescription" })}</p>
          <ExternalButton
            text="demo"
            href="https://oakgames.it/games/foxrunner/"
          />
        </Info>
      </Top>
      <Images>
        <ImageWrapper>
          <StaticImage
            src="../../images/foxrunner-screen-1.jpg"
            alt="logo"
            placeholder="blurred"
            quality={100}
            objectFit="cover"
            style={{ height: "100%", width: "100%" }}
          />
        </ImageWrapper>
        <ImageWrapper>
          <StaticImage
            src="../../images/foxrunner-screen-2.jpg"
            alt="logo"
            placeholder="blurred"
            objectFit="cover"
            quality={100}
            style={{ height: "100%", width: "100%" }}
          />
        </ImageWrapper>
        <ImageWrapper>
          <StaticImage
            src="../../images/foxrunner-screen-3.jpg"
            objectFit="cover"
            alt="logo"
            placeholder="blurred"
            quality={100}
            style={{ height: "100%", width: "100%" }}
          />
        </ImageWrapper>
        <ImageWrapper>
          <StaticImage
            src="../../images/foxrunner-screen-4.jpg"
            objectFit="cover"
            alt="logo"
            placeholder="blurred"
            quality={100}
            style={{ height: "100%", width: "100%" }}
          />
        </ImageWrapper>
      </Images>
      <Description>
        {intl.formatMessage({ id: "FoxrunnerBigDescription" })}
      </Description>
    </Container>
  )
}
